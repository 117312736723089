.am-accordion {
  position: relative;
  border-top: 1PX solid #ddd;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-accordion {
    border-top: none;
  }

  html:not([data-scale]) .am-accordion::before {
    content: '';
    position: absolute;
    background-color: #ddd;
    display: block;
    z-index: 1;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
}

@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .am-accordion::before {
    -webkit-transform: scaleY(0.33);
    transform: scaleY(0.33);
  }
}

.am-accordion-anim-active {
  transition: all 0.2s ease-out;
}

.am-accordion .am-accordion-item .am-accordion-header {
  position: relative;
  color: #000;
  font-size: 0.453333rem;
  height: 1.173333rem;
  line-height: 1.173333rem;
  background-color: #fff;
  box-sizing: content-box;
  padding-left: 0.4rem;
  padding-right: 0.8rem;
  border-bottom: 1PX solid #ddd;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-accordion .am-accordion-item .am-accordion-header {
    border-bottom: none;
  }

  html:not([data-scale]) .am-accordion .am-accordion-item .am-accordion-header::after {
    content: '';
    position: absolute;
    background-color: #ddd;
    display: block;
    z-index: 1;
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
}

@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .am-accordion .am-accordion-item .am-accordion-header::after {
    -webkit-transform: scaleY(0.33);
    transform: scaleY(0.33);
  }
}

.am-accordion .am-accordion-item .am-accordion-header i {
  position: absolute;
  display: block;
  top: 0.4rem;
  right: 0.4rem;
  width: 0.4rem;
  height: 0.4rem;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D%2216%22%20height%3D%2226%22%20viewBox%3D%220%200%2016%2026%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3Cg%20id%3D%22UI-KIT_%E5%9F%BA%E7%A1%80%E5%85%83%E4%BB%B6%22%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cg%20id%3D%229.9%E5%9F%BA%E7%A1%80%E5%85%83%E4%BB%B6%22%20transform%3D%22translate(-5809.000000%2C%20-8482.000000)%22%20fill%3D%22%23C7C7CC%22%3E%3Cpolygon%20id%3D%22Disclosure-Indicator%22%20points%3D%225811%208482%205809%208484%205820.5%208495%205809%208506%205811%208508%205825%208495%22%3E%3C%2Fpolygon%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.am-accordion .am-accordion-item .am-accordion-header[aria-expanded~="true"] i {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.am-accordion .am-accordion-item .am-accordion-content {
  overflow: hidden;
  background: #fff;
}

.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box {
  font-size: 0.4rem;
  color: #333;
  position: relative;
  border-bottom: 1PX solid #ddd;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box {
    border-bottom: none;
  }

  html:not([data-scale]) .am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box::after {
    content: '';
    position: absolute;
    background-color: #ddd;
    display: block;
    z-index: 1;
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
}

@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box::after {
    -webkit-transform: scaleY(0.33);
    transform: scaleY(0.33);
  }
}

.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box .am-list-body {
  border-top: 0;
}

.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box .am-list-body:before {
  display: none !important;
}

.am-accordion .am-accordion-item .am-accordion-content.am-accordion-content-inactive {
  display: none;
}